<template>
  <div class="column">
    <q-table flat wrap-cells
      :data="tasks"
      :columns="columns"
      row-key="id"
      :rows-per-page-options="rowsPerPageOptions"
      :pagination.sync="pagination"
      :loading="loading"
      :filter="filter"
      @request="onRequest"
      binary-state-sort>
      <q-tr slot="body" slot-scope="props" :props="props"
        @click.native="$router.push({ name: 'task-show', params: { id: props.row.id } })"
        class="cursor-pointer">
        <q-td v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
          <q-icon v-if="col.name === 'statut' && col.value" :name="$getIcon(col.name, col.value)" size="18px" />
          <q-icon v-else-if="col.name === 'qualification' && col.value" :name="$getIcon(col.name, col.value)" size="18px" />
          <div v-else-if="col.value" v-html="col.value" />
        </q-td>
      </q-tr>
    </q-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'TasksList',

  data () {
    return {
      filter: '',
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      tasks: 'tasks/getTasks',
      filters: 'tasks/getFilters',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    }),
    pagination: {
      ...mapGetters({ get: 'tasks/getPagination' }),
      ...mapMutations({ set: 'tasks/setPagination' })
    },

    columns () {
      if (this.tasks.length === 0) return []
      else {
        const fields = ['label', 'auteur', 'destinataire', 'model', 'date_rappel', 'date_cible', 'statut', 'qualification']
        const sortable = ['label', 'auteur', 'destinataire', 'date_cible', 'statut', 'qualification']
        return fields.map(key => {
          let label = this.$t(`tasks.fields.${key}.label`)
          let align = 'left'
          let autoWidth = false
          let formatFn = val => val

          if (key === 'created_at' || key === 'updated_at') {
            label = this.$t(key)
            formatFn = val => this.$formatDate(val)
          } else if (key === 'date_cible' || key === 'date_rappel') {
            formatFn = val => this.$formatDate(val)
          } else if (key === 'auteur' || key === 'destinataire') {
            formatFn = val => val ? val['display_name'] : ''
          } else if (key === 'statut' || key === 'qualification') {
            align = 'center'
            autoWidth = true
          } else if (key === 'model') {
            formatFn = val => {
              if (val && val['groupe_cible']) {
                let entityLabel = ''
                if (val.entities.length > 0) {
                  entityLabel = val.entities[0].label ? `${val.entities[0].label}` : ''

                  val.agents.forEach((entity, index) => {
                    if (index === 0) return
                    entityLabel += entity.label ? `${entityLabel === '' ? '' : (' - ')}${entity.label}` : ''
                  })
                }

                let agentLabel = ''
                if (val.agents.length > 0) {
                  agentLabel = val.agents[0].PRENOM && val.agents[0].NOMITV ? `${entityLabel === '' ? '' : (' - ')}${val.agents[0].PRENOM} ${val.agents[0].NOMITV}` : ''

                  val.agents.forEach((agent, index) => {
                    if (index === 0) return
                    agentLabel += agent.PRENOM && agent.NOMITV ? `${agentLabel === '' ? '' : (' - ')}${agent.PRENOM} ${agent.NOMITV}` : ''
                  })
                }

                let walletLabel = ''
                if (val.wallets.length > 0) {
                  walletLabel = val.wallets[0].NOMPTF ? `${agentLabel === '' && entityLabel === '' ? '' : (' - ')}${val.wallets[0].NOMPTF}` : ''

                  val.wallets.forEach((wallet, index) => {
                    if (index === 0) return
                    walletLabel += wallet.NOMPTF ? `${walletLabel === '' && entityLabel === '' ? '' : (' - ')}${wallet.NOMPTF}` : ''
                  })
                }

                let displayedLabel = entityLabel + agentLabel + walletLabel

                return `${this.$t(`sousCibles.fields.type.options.${val['type']}`)} - ${displayedLabel}`
              } else if (val) {
                return val['NOMPTF'] || val['TITULAIRE'] || val['label']
              }
              return val && val.label
            }
          }

          return {
            name: key,
            align: align,
            label: label,
            field: key,
            format: formatFn,
            sortable: sortable.indexOf(key) >= 0,
            autoWidth: autoWidth
          }
        })
      }
    },

    statut   () {
      return this.$route.meta['statut']
    }
  },

  watch: {
    filters: {
      handler (newVal, oldVal) {
        this.$nextTick(() => {
          this.onRequest({ pagination: this.pagination })
        })
      },
      deep: true
    }
  },

  mounted () {
    if (this.filters) { this.onRequest({ pagination: this.pagination }) }
  },

  methods: {
    onRequest ({ pagination, filter }) {
      this.$store.commit('tasks/setPagination', pagination)

      let params = { ...pagination, statut: this.statut }
      this.filters.forEach(filter => { params[filter.id] = filter.value })

      this.loading = true
      this.$store.dispatch('tasks/fetchTasks', params)
        .finally(() => { this.loading = false })
    }
  }
}
</script>
